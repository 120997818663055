import { createSlice } from '@reduxjs/toolkit';



export const countSlice = createSlice({
    name: 'count',

    initialState: {
        counts: {
            user_count: 0,
            jobs_count:0,
        }

    },

    reducers: {
        setCount: (state, action) => {
            state.counts = action.payload;
        },
    },
})



export const { setCount } = countSlice.actions

export default countSlice.reducer