import React, { useEffect, useState } from 'react'
import Editor from '../../common/Editor'
import Button from '../../common/Button';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { toaster } from '../../apis/commonFunctions';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

export default function TermsCondition() {
    const [value, setValue] = useState('');
    const [load, setLoad] = useState(true)
    const nav = useNavigate()

    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.GET_PRIVACY_POLICY}?type=2`);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    toast.info(result.data.message)
                }
            } else {
                setValue(result.data.data.content)
                setLoad(false)
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    const updatePrivacy = async () => {
        try {
            const result = await patchRequest(APIS.UPDATE_CONTENT, { content: value, type: 2 });
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    toast.info(result.data.message)
                }
            } else {
                toast.success(result.data.message)
                fetchData()
            }
        } catch (err) {
            toast.error(err.message);
        }
    }


    useEffect(() => {
        fetchData();
    }, [])
    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="top_bar row align-items-center">
                        <div className="col-lg-12">
                            <div className="pagetitle">
                                <button className='d-flex align-items-center mt-3'>Terms and Conditions</button>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 text-end">
                    <button className='blank_btn pe-0'>
                        <Filter />
                    </button>
                </div> */}
                    </div>
                    <div className="row left_col mt-5">
                        <Editor onChange={(value) => { setValue(value) }} value={value} />
                        <div className='mt-5'>
                            <Button text="Save Data" type="text" onClick={updatePrivacy} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}
