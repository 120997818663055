import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

export default function Profile_dropdown() {
    const router = useNavigate();
    
    const logout = () => {
        localStorage.clear();
        setTimeout(() => {
            router('/')
        })
    }

    const info = useSelector(state => state.details)

    return (
        <div className="profile">
            <div className="dropdown">
                {info.profile_pic !== "" ?
                    <img src={info.profile_pic} alt="userDefault" className='img-fluid rounded-circle sidebar-profile' />
                    :
                    <img src="/user.jpg" alt="userDefualt" className='img-fluid rounded-circle sidebar-profile' />
                }
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {info.name}
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li><Link className="dropdown-item" to="/admin/profile">Profile</Link></li>
                    <li><Link className="dropdown-item" to="/admin/change-password">Change Password</Link></li>
                    <li><Link className="dropdown-item" onClick={logout}>Logout</Link></li>
                </ul>
            </div>
        </div>
    )
}
