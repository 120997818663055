import React from 'react'
import { Link } from 'react-router-dom'

const Dropdown = ({ onSelect,title, options = [], style }) => {
    return (
        <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={style}>
                {title}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {options.map(item => <button className="dropdown-item" onClick={()=>onSelect(item.value)}>{item.label}</button>)}
            </div>
        </div>
    )
}

export default Dropdown