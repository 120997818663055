import React, { useEffect } from 'react'
import Profile_dropdown from './Profile_dropdown'
import { setDetails } from '../redux/slices/detailsSlice';
import { useDispatch } from 'react-redux';
import { setCount } from '../redux/slices/countSlice';

export default function Header() {
    const dispatcher = useDispatch()

    useEffect(() => {
        dispatcher(setDetails(JSON.parse(localStorage.getItem('info'))))
        dispatcher(setCount(JSON.parse(localStorage.getItem('counting'))))
    }, [])

    return (
        <>
            <header className='header fixed-top bg-white'>
                <div className="toggle_logo">
                    <div className="inner_logo">
                        <img src="/logo.png" alt="logo" className='img-fluid' style={{ maxHeight: "25px" }} />
                    </div>
                </div>
                <Profile_dropdown />
            </header>


        </>

    )
}
