import React, { useEffect, useState } from 'react'
import { Block, Calculator, Delete, Eye, Pencil, Search } from '../../common/Icons'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import Button from '../../common/Button'
import Switch from "react-switch";
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import Pagination from '../../common/Pagination'
import Search_input from '../../common/Search_input'
import moment from 'moment/moment'
import Loader from '../Loader'



export default function Groups() {
    const [all, setAll] = useState([]);
    const router = useNavigate()
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(8);
    const [load, setLoad] = useState(true);
    const [mainid, setMainId] = useState("");
    const [total, setTotal] = useState(0)
    const [params] = useSearchParams();
    const [obj, setObj] = useState({})
    const [members, setMembers] = useState([])
    // console.log(Array.from(params.entries())," thishsishihsih")
    const [search, setSearch] = useState("");


    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.GET_GROUPS}?search=${search}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)
                setLoad(false)

            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    function handlePageClick({ selected }) {
        setSkip(selected * limit)
    }

    const deleteFinance = async (id) => {
        try {
            const { data } = await deleteRequest(`${APIS.USER}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const handleBlock = async () => {
        try {
            const { data } = await patchRequest(`${APIS.BLOCK_UNBLOCK_USER}/${obj._id}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [search, skip])

    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="top_bar row align-items-center mt-3">
                        <div className="col-lg-6">
                            <div className="pagetitle">
                                Groups Management <br />
                            </div>
                        </div>
                        <div className="col-lg-6 text-end">
                        <Search_input placeholder={'Search by group name...'} search={search} handleSearch={(e) =>setSearch(e.target.value)} icon={<Search />} className='search_input' />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colSpan="4">
                                        <th>S.No</th>
                                        <th>Groups</th>
                                        <th>Members</th>
                                        <th>Admin</th>
                                        <th>Reports Count</th>
                                        <th>Created At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {all.length > 0
                                        ?
                                        all.map((element, index) => (
                                            <tr key={element._id}>
                                                <td>{index + 1}</td>
                                                <td>{element.group_photo !== "" ?
                                                    <img src={element.group_photo} className="groupPic" />
                                                    :
                                                    <img src='./empty-group.png' className="groupPic" />
                                                }&nbsp;&nbsp;
                                                    {element.group_name}
                                                </td>
                                                <td className='td-images'>
                                                    <button className='view-groups'>{element.users.length}</button>
                                                </td>
                                                <td> {element.admin_name ? element.admin_name : 'Not Found'}</td>
                                                <td>{element.reported_count}</td>
                                                <td style={{ textTransform: "unset" }}>{moment(element.createdAt).format("LL")}</td>
                                                <td>
                                                    <div className="actions">
                                                        <button className='view p-2' data-bs-toggle="tooltip" data-bs-placement="top" title="View Details">
                                                            <Link className='view' to={'/groups/' + element._id}>
                                                                <Eye />
                                                            </Link>
                                                        </button>
                                                        {/* <button className='view p-2'>
                                                    <Link className='view' to={'/user-emi-management/' + element._id}>
                                                        <Calculator />
                                                    </Link>
                                                </button> */}
                                                        {/* <button className='block' style={{ color: (element.admin_blocked ? "#0F993E" : "#ff4c27") }} onClick={() => setObj(element)}>
                                                            <Block />
                                                        </button>
                                                        <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#decline_modal">
                                                            <Delete />
                                                        </button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : <NoDataRow />}
                                    {total > limit && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={total} limit={limit} /></td></tr>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ConfirmationPopup message={"Do you really want to delete this user ?"} onYes={() => deleteFinance()} />
                </>
            }


        </>
    )
}
