import React from "react";

const Button = ({ className, text, onClick,loader=false, ...props }) => {
    return (
        <>
            <button className={className} disabled={loader} onClick={onClick} {...props}>
                {loader ? <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                </div> : text}
                
            </button>
        </>
    )
}
export default Button;