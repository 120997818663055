import React, { useEffect, useState } from 'react'
import Loader from '../Loader'
import toast from 'react-hot-toast'
import { getRequest } from '../../apis/functions'
import APIS from '../../apis/routes';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import NoDataRow from '../../common/NoDataRow';

const GroupDetails = () => {
    const [load, setLoad] = useState(true)
    const [details, setDetails] = useState({})
    const router = useParams()

    const fetchDetails = async () => {
        try {
            let result = await getRequest(`${APIS.GET_GROUP_DETAILS}${router.id}`)
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                setDetails(result.data.data)
                setLoad(false)
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
        }
    }
    useEffect(() => {
        fetchDetails()
    }, [])
    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="pagetitle mb-5 mt-3" style={{ width: "fit-content" }}>
                        Group Details
                    </div>
                    <div className="outer_div mt-4">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="sub_title mb-4">Group Admin</div>
                                <div className="p-0 single_column mt-2">
                                    {details.admin?
                                    <>
                                    <div>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Full Name</div>
                                            <div className="name mt-2">{details.admin && details.admin.firstname + " " + details.admin && details.admin.lastname}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Contact Info</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.admin && details.admin.mobile === "" ? 'Not added yet.' : details.admin && details.admin.country_code + " " + details.admin && details.admin.mobile}</div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Not available</div>
                                        </div>}

                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="sub_title  mb-4">Group Details</div>
                                <div className="p-0 single_column mt-2">
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Group</div>
                                        <div className="name mt-2">
                                            {details.group_photo !== "" ?
                                                <img src={details.group_photo} className="groupPic" />
                                                :
                                                <img src='/empty-group.png' className="groupPic" />
                                            }
                                            &nbsp;&nbsp;
                                            {details.group_name}
                                        </div>
                                    </div>
                                    <div className="detail_wrapper mt-3">
                                        <div className="small_heading">Created At</div>
                                        <div className="name mt-2">{moment(details.createdAt).format('LLL')}</div>
                                    </div>
                                    <div className="detail_wrapper mt-3">
                                        <div className="small_heading">Members</div>
                                        <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.users.length}</div>
                                    </div>
                                    <div className="detail_wrapper mt-3">
                                        <div className="small_heading">Reported Count</div>
                                        <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.reportedBy.length}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row mt-4'>
                            <div className="detail_wrapper">
                                <div className="small_heading">This group reported by these users.</div>
                            </div>
                            <div className="table-responsive mt-3">
                                <table className='table table-lg'>
                                    <thead className='table-light'>
                                        <tr colSpan="4">
                                            <th>S.No</th>
                                            <th>User</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details.reportedBy && details.reportedBy.length > 0
                                            ?
                                            details.reportedBy.map((element, index) => (
                                                <tr key={element._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{element.profile_pic !== "" ?
                                                        <img src={element.profile_pic} className="groupPic" />
                                                        :
                                                        <img src='./user.jpg' className="groupPic" />
                                                    }&nbsp;&nbsp;
                                                        {element.firstname + " " + element.lastname}
                                                    </td>
                                                    <td>{element.email}</td>
                                                </tr>
                                            ))
                                            : <NoDataRow />
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default GroupDetails


