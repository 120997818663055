import React, { useRef, useState } from 'react'
import Input from './Input'
import Button from './Button';
import Image from './Image';

const EditProfile = ({ firstname, lastname, email, mobile, gender, dob, username, bio, joining_date, profile_pic, onUpdateProfile, loader = false }) => {

    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(profile_pic ? profile_pic : '/user.jpg')
    const inputRef = useRef();
    function onFileChoose(e) {
        setFile(e.target.files[0]);
        if (e.target.files[0]) {
            setPreview(window.URL.createObjectURL(e.target.files[0]))
        }
    }

    const [details, setData] = useState({
        firstname,
        lastname,
        email,
        gender,
        mobile,
        dob,
        username,
        bio
    })

    function onEdit() {
        inputRef.current.click();
    }

    function handleChanges(e) {
        if (e.target.name === "withdraw_days") {
            if (!isNaN(e.target.value)) {
                setData({
                    ...details,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setData({
                ...details,
                [e.target.name]: e.target.value
            })
        }
    }

    function handleSelection(e) {
        if (e.target.checked) {
            setData({
                ...details,
                privillages: [...details.privillages.filter(item => item !== e.target.value), e.target.value]
            })
        } else {
            setData({
                ...details,
                privillages: [...details.privillages.filter(item => item !== e.target.value)]
            })
        }
    }

    return (
        <div className={"row " + (joining_date ? "border-bottom" : "")}>
            <div className="col-lg-2 col-md-2">
                <div className="profile_image mt-4">
                    <Image src={preview} alt="" className="rounded-circle img-fluid" onClick={onEdit} />
                    <input type='file' multiple="false" onChange={onFileChoose} className='opacity-0' ref={inputRef} />
                </div>
            </div>
            <div className="col-lg-10 col-md-10">
                <ul className="profile_list p-0 edit_profile">
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the first name' onChange={handleChanges} value={details.firstname} name="firstname" />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the last name' onChange={handleChanges} value={details.lastname} name="lastname" />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="email" placeholder='Enter the email' name="email" value={details.email} onChange={handleChanges} />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the mobile' name="mobile" value={details.mobile} onChange={handleChanges} />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the gender' name="gender" value={details.gender} onChange={handleChanges} />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the dob' name="dob" value={details.dob} onChange={handleChanges} />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the username' name="username" value={details.username} onChange={handleChanges} />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the bio' name="bio" value={details.bio} onChange={handleChanges} />
                        </div>
                    </li>
                    <li >
                        <div className="detail_wrapper left_col">
                            <Button loader={loader} type="button" text="Update Profile" onClick={() => {
                                onUpdateProfile(details, file);
                            }} />
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default EditProfile