import React, { useEffect, useState } from 'react'
import { Delete, Eye, Search } from '../../common/Icons'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import LoadingRow from '../../common/LoadingRow'
import Pagination from '../../common/Pagination'
import Loader from '../Loader'
import moment from 'moment'
import Search_input from '../../common/Search_input'
import toast from 'react-hot-toast'

export default function Booking() {
    const [all, setAll] = useState([]);
    const [obj, setObj] = useState({});
    const nav = useNavigate()
    const [skip, setSkip] = useState(0)
    const [search, setSearch] = useState("");
    const [mainid, setMainId] = useState("");
    const [load, setLoad] = useState(true);
    const LIMIT = 8

    const fetchData = async () => {
        console.log('search',typeof(search))
        try {
            const result = await getRequest(`${APIS.GET_BOOKINGS}?search=${search}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)
                setLoad(false)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const deleteFunction = async () => {
        try {
            const { data } = await deleteRequest(`${APIS.ITINERARIES}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                setSkip(0)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const submitRequest = async (status) => {
        try {
            let id = obj._id
            let updatedStatus = {
                status: status
            }
            const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, updatedStatus);
            if (data.status) {
                toaster(data.message, true);
                fetchData();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false);
        }
    }

    function handlePageClick({ selected }) {
        setSkip(selected * LIMIT)
    }

    const [total, setTotal] = useState(0)

    useEffect(() => {
        fetchData();
    }, [search, skip])

    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="top_bar row align-items-center mt-3">
                        <div className="col-lg-6">
                            <div className="pagetitle">
                                Bookings
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <Search_input placeholder={'Search booked by user...'} search={search} handleSearch={(e) =>setSearch(e.target.value)} icon={<Search />} className='search_input justify-content-start w-100' />
                        </div>

                    </div>
                    <div className="row mt-4">
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Booking ID</th>
                                        <th>Booked By User</th>
                                        <th>Booked By User Contact Info</th>
                                        <th>No. of Persons</th>
                                        <th>Booked On</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {load ? <LoadingRow /> : all.length > 0
                                        ?
                                        all.map((element, index) => (
                                            <tr key={element._id}>
                                                <td>{element._id}</td>
                                                <td>{element.booked_by}</td>
                                                <td>{element.phone_number}</td>
                                                <td>{element.no_of_persons}</td>
                                                <td>{moment(element.createdAt).format('LL')}</td>
                                                {/* <td>
                                                    {element.payment_status === 0 ?
                                                        <span className="badge pending">PENDING</span>
                                                        : element.status === 1 ?
                                                            <span className="badge accepted">COMPLETED</span>
                                                            : element.status === 2 ?
                                                                <span className="badge rejected">FAILED</span>
                                                                : <span className="badge rejected">FAILED</span>
                                                    }
                                                </td> */}
                                                <td>
                                                    {element.status === 0 ?
                                                        <span className="badge pending">PENDING</span>
                                                        : element.status === 1 ?
                                                            <span className="badge accepted">CONFIRMED</span>
                                                            : element.status === 2 ?
                                                                <span className="badge cancelled">CANCELLED</span>
                                                                : <span className="badge cancelled">CANCELLED</span>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="actions">
                                                        <Link className='view' to={'/bookings/' + element._id} data-bs-toggle="tooltip" data-bs-placement="top" title="View Details">
                                                            <button className='block'>
                                                                <Eye />
                                                            </button>
                                                        </Link>
                                                        {/* <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#decline_modal">
                                                            <Delete />
                                                        </button> */}
                                                        {/* <Button className="btn btn-success table-btn" onClick={() => acceptReject(element._id, "Approved")} text={<Tick />} style={{ color: "green", fontWeight: "100", padding: "5px 5px" }} />
                                                {element.status === "Pending" && <Button className="btn btn-danger table-btn" onClick={() => acceptReject(element._id, "Rejected")} text={<Cancel />} style={{ color: "red", fontWeight: "100", padding: "5px 5px" }} />} */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : <NoDataRow />}
                                    {total > LIMIT && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={total} limit={LIMIT} /></td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ConfirmationPopup message={"Do you really want to delete this request ?"} onYes={() => deleteFunction()} />
                </>

            }

        </>
    )
}
