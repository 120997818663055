import React from 'react'

const Error = () => {
    return (
        <div className='notfound text-center'>
            <h2>Details are not found for this itinerary!</h2>
        </div>
    )
}

export default Error
