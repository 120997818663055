import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const nav = useNavigate()
  const location = useLocation()
  var auth = localStorage.getItem("x_token")

  useEffect(()=>{
    if(location.pathname === "/" && auth){
      nav('/dashboard')
    }
  },[location])

  return (
    <div className='notfound text-center'>
      <h2>The Page you are looking for does not exist.</h2>
    </div>
  )
}

export default PageNotFound