import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { CameraIcon } from '../common/Icons';
import toast from 'react-hot-toast';

const SingleUpload = ({ onUpload }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState('');
    const [progress, setProgress] = useState(0);
    const [save, setSave] = useState(false);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file))
        } else {
            toast.error('Please select a valid image file (JPEG/JPG/PNG)');
        }
    };

    const handleUpload = () => {
        if (!selectedFile) {
            toast.error('Please select a file to upload');
            return;
        }

        setSave(true);

        // Configuring AWS SDK
        AWS.config.update({
            accessKeyId: 'AKIA4MTWOBTUL3ZVT64P',
            secretAccessKey: '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',
            region: 'us-east-1'
        });

        const s3 = new AWS.S3();
        const params = {
            Bucket: 'dannas3multimedia',
            Key: selectedFile.name,
            Body: selectedFile,
            ContentType: selectedFile.type
        };

        s3.upload(params).on('httpUploadProgress', (evt) => {
            const percentage = parseInt((evt.loaded * 100) / evt.total);
            setProgress(percentage);
        }).send((err, data) => {
            if (err) {
                console.error('Error uploading file:', selectedFile.name, err);
                toast.error('Failed to upload file');
                setProgress(0);
                setSave(false);
            } else {
                toast.success('Image uploaded successfully');
                setProgress(0);
                setPreview('')
                setSave(false);
                setSelectedFile(null);
                onUpload(data.Location); // Send uploaded file location to callback
            }
        });
    };

    return (
        <div>
            <label htmlFor="upload" className="name mt-2 text-lowercase"><CameraIcon /></label>
            <input id="upload" type='file' accept="image/jpeg, image/png, image/jpg" style={{ display: "none" }} onChange={handleFileInputChange}/><br/>
            {preview!==""?<><img src={preview} style={{width:"100px", height:'100px'}}/><br/></>:null}
            <button className="newBtn mt-2" onClick={handleUpload} disabled={save||selectedFile===null}>Upload</button>
            {progress > 0 && <p>Uploading: {progress}%</p>}
        </div>
    );
};

export default SingleUpload;
