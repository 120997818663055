import React, { useEffect, useState } from 'react'
import EditProfile from '../../common/EditProfile'
import { toaster } from '../../apis/commonFunctions'
import { getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import { useDispatch } from 'react-redux'
import { setDetails } from '../../redux/slices/detailsSlice'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Image from '../../common/Image'
import { CameraIcon, EditData } from '../../common/Icons'
import Input from '../../common/Input'
import UploadImages from '../UploadImages'
import SingleUpload from '../SingleUpload'

export default function AdminProfile() {
    const [userDetails, setData] = useState({ name: "" })
    const [edit, setEdit] = useState(false)
    const [load, setLoad] = useState(false)
    const nav = useNavigate()

    const [toggle, setToggle] = useState(false);
    const dispatcher = useDispatch()

    const getProfile = async () => {
        try {
            const result = await getRequest(APIS.GET_ADMIN_PROFILE);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    toast.info(result.data.message)
                }
            } else {
                setData(result.data.data)
                dispatcher(setDetails(result.data.data))
                localStorage.setItem('info', JSON.stringify(result.data.data))
                setLoad(false)
            }
        } catch (err) {
            // toast.error(err.message);
        }
    }

    const handleImageComplete = (newImages) => {
        setData(prevDetails => ({
            ...prevDetails, profile_pic: newImages
        }));
    };

    function handleChanges(e) {
        setData({
            ...userDetails,
            [e.target.name]: e.target.value.trim()
        })
    }

    async function updateProfile() {
        try {
            const result = await patchRequest(APIS.UPDATE_ADMIN, userDetails);
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                getProfile()
                setEdit(false)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        getProfile()
    }, []);
    return (
        <>
            <div className="outer_div mt-4">
                {!toggle ?
                    <>
                        <div className='row'>
                            {!edit ? <div className='d-flex justify-content-end align-items-end'>
                                <div className='editIcon' onClick={() => setEdit(true)}>
                                    <EditData />
                                </div>
                            </div> :
                                <div style={{ height: '49px' }}></div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-2">
                                <div className="profile_image">
                                    <Image src={userDetails.profile_pic !== "" ? userDetails.profile_pic : "/user.jpg"} alt="" className="rounded-circle img-fluid" />
                                </div>
                                
                            </div>
                            <div className="col-lg-10 col-md-10">
                                <ul className="profile_list p-0">
                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Name</div>
                                            {!edit ?
                                                <div className="name mt-2">{userDetails.name}</div>
                                                :
                                                <div className="d-flex justify-content-start gap-3 flex-column">
                                                    <Input type={"text"} placeholder='name' value={userDetails.name} disabled={edit ? false : true} onChange={handleChanges} name="name" />
                                                </div>
                                            }
                                        </div>
                                    </li></div>
                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Email Address</div>
                                            <div className="name mt-2 text-lowercase">{userDetails.email}</div>
                                        </div>
                                    </li></div>
                                    {edit ?
                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Choose Image</div>
                                            <SingleUpload onUpload={handleImageComplete} />
                                        </div>
                                    </li></div>
                                    : null}
                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            {edit ? <button className='newBtn' onClick={updateProfile} disabled={userDetails.name === ""}>Save Changes</button> : null}
                                        </div>
                                    </li></div>
                                </ul>
                            </div>
                        </div>
                    </>
                    : <EditProfile firstname={userDetails.firstname} lastname={userDetails.lastname} email={userDetails.email} profile_pic={userDetails.profile_pic} onUpdateProfile={updateProfile} />}<div className="card_botom">
                </div>
            </div>
        </>
    )
}