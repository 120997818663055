import React, { useEffect, useRef, useState } from 'react'
import { CancelChip, DeleteIcon, ImagesIcon, More, VideosIcon } from '../../common/Icons'
import toast from 'react-hot-toast'
import UploadVideo from '../UploadVideo'
import UploadImages from '../UploadImages'
import AWS from 'aws-sdk';
import { airports } from '@nwpr/airport-codes';
import { postRequest } from '../../apis/functions'
import APIS from '../../apis/routes';
import { useNavigate } from 'react-router-dom'

const CreateItinerary = () => {
    const [tabs, setTabs] = useState('DATES')
    const nav = useNavigate();
    const inputRefs = useRef([]);
    const inputDestination = useRef(null);
    const [acc, setAcc] = useState([])
    const [departure, setDeparture] = useState([])
    const [arrival, setArrival] = useState([])
    const [filteredAirports, setFilteredAirports] = useState([]);
    const [currentMealIndex, setCurrentMealIndex] = useState(-1);
    const [data, setData] = useState(
        {
            destination_address: '',
            start_date: 0,
            end_date: 0,
            no_of_nights: 0,
            departure_station: '',
            arrival_station: '',
            photos: [],
            no_of_days: 0,
            location: {
                type: "Point",
                coordinates: [0, 0]
            },
            status: 3,
            tab_name: tabs,
            meals: '',
            description: '',
            video: '',
            is_created_admin: true,
            accomondations: [],
            month: 0,
            number_of_people: 0,
            charges: 0

        })

    const handleAddData = (e) => {
        const { id, name, value } = e.target;
        // Handle specific cases based on input name
        if (name === 'no_of_days' || name === 'no_of_nights' || name === 'charges') {
            if (isNaN(value)) {
                return;
            }
        }
        if (name === 'no_of_days') {
            if (parseInt(value) > 14) {
                toast.error('Number of days should not greater then 14')
                return;
            } else if (parseInt(value) === 0) {
                toast.error('Number of days should not be zero!');
            } else {
                // Calculate no_of_nights based on no_of_days
                setData(prevDetails => ({
                    ...prevDetails,
                    [name]: parseInt(value)
                }));
                setData(prevDetails => ({
                    ...prevDetails,
                    no_of_nights: parseInt(value) - 1
                }));
            }
        } else if (name === 'month') {
            // Handle month input: convert to timestamp if needed
            const monthValue = new Date(value).getTime(); // Assuming value is a valid date string
            setData(prevObj => ({
                ...prevObj,
                [name]: monthValue,
            }));
        } else if (name === 'start_date' || name === 'end_date') {
            // Handle start_date and end_date inputs: convert date string to timestamp
            const dateValue = new Date(value).getTime(); // Assuming value is a valid date string
            setData(prevObj => ({
                ...prevObj,
                [name]: dateValue,
            }));
        } else if (name === 'charges') {
            setData(prevDetails => ({
                ...prevDetails,
                [name]: parseInt(value)
            }));
        } else if (name === 'number_of_people') {
            setData(prevDetails => ({
                ...prevDetails,
                [name]: parseInt(value)
            }));
        } else {
            // Default case: handle other inputs directly
            setData(prevObj => ({
                ...prevObj,
                [name]: value,
            }));
            setCurrentMealIndex(parseInt(id))
        }

    };


    const handleAddAccommodation = (e, index) => {
        const { name, value } = e.target;
        const updatedAcc = [...acc];
        updatedAcc[index] = {
            ...updatedAcc[index],
            [name]: value
        };
        setAcc(updatedAcc);
    };

    const handleSearch = (stationType) => {
        const searchTerm = stationType === 'departure' ? data.departure_station : data.arrival_station;
        let filtered = airports.filter(airport =>
            airport.name.toLowerCase().includes(searchTerm.toLowerCase())
            || airport.city.toLowerCase().includes(searchTerm.toLowerCase())
            || (airport.iata && airport.iata.includes(searchTerm.toUpperCase().trim()))
        );
        return filtered;
    }

    const handleAddAirport = (name, city, key) => {
        setData(prevDetails => ({
            ...prevDetails, [key]: name + " " + city
        }));
        setDeparture([])
        setArrival([])
    }

    const createNew = async () => {
        try {
            let final = {
                ...data, accomondations: acc, tab_name: tabs
            }
            const result = await postRequest(APIS.CREATE_ITINERARY, final);
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                nav('/itinerary-management')
            }
        } catch (err) {
            toast.error(err.message)
        }
    }

    const handleDeleteImages = (image, itemToRemove) => {
        try {
            const parts = image.split('/');
            // Extract the last part of the URL (which is the key)
            const key = parts[parts.length - 1];

            AWS.config.update({
                accessKeyId: 'AKIA32MQLJXNBHHKIR5J',
                secretAccessKey: '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',
                region: 'us-east-1'
            });
            const s3 = new AWS.S3();
            const params = {
                Bucket: 'danna-multimedia-s3',
                Key: key // Specify the key (name) of the object you want to delete
            };

            s3.deleteObject(params, (err) => {
                if (err) {
                    toast.error('Error deleting object:', err);
                    return;
                } else {
                    if (itemToRemove === null) {
                        setData(prevDetails => ({
                            ...prevDetails, video: ''
                        }));
                    } else {
                        const updatedItems = data.photos && data.photos.filter((item, index) => index !== itemToRemove);
                        setData(prevDetails => ({
                            ...prevDetails, photos: updatedItems
                        }));
                    }
                    toast.success('Deleted successfully:');
                }
            });

        } catch (error) {
            toast.error(error.messageF)
        }

    }


    const handleMore = () => {
        let arrayAcc = [{
            accomondation_address: "",
            tours: "",
            meals: [],
            location: {
                type: "Point",
                coordinates: [0, 0]
            }
        }]
        const multipliedArray = Array.from({ length: data.no_of_days }, () => ({ ...arrayAcc[0] }));
        setAcc(multipliedArray)
    }

    const handlePushMeals = (index) => {
        const updatedAcc = [...acc];
        updatedAcc[index] = {
            ...updatedAcc[index],
            meals: [...updatedAcc[index].meals, data.meals.trim()]
        };
        setAcc(updatedAcc);
        setData(prevDetails => ({
            ...prevDetails, meals: ''
        }));
        document.getElementById(index).value = ''
    };

    const handleRemoveMeal = (indexToRemove, accIndex) => {
        const updatedAcc = [...acc];
        updatedAcc[accIndex] = {
            ...updatedAcc[accIndex],
            meals: updatedAcc[accIndex].meals.filter((meal, index) => index !== indexToRemove)
        };
        setAcc(updatedAcc);
    }
    const handleTabs = (value) => {
        setTabs(value)
        setData(prevDetails => ({
            ...prevDetails, no_of_nights: 0, no_of_days: 0
        }));
    }

    const initMap2 = () => {
        const input = inputDestination.current;
        const searchBox = new window.google.maps.places.SearchBox(input);
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            if (places.length === 0) {
                toast.error('no place found!')
            } else {
                setData(prevDetails => ({
                    ...prevDetails, 
                    destination_address:places[0].formatted_address,
                    'location': {
                        type: 'Point',
                        coordinates: [places[0].geometry.location.lng(), places[0].geometry.location.lat()]
                    }
                }));
            }
        });
    };

    const initMap = () => {
        acc.forEach((element, index) => {
            const input = inputRefs.current[index]
            const searchBox = new window.google.maps.places.SearchBox(input);
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces();
                if (places.length === 0) {
                    toast.error('No place found');
                    return;
                }
                const updatedAcc = [...acc];
                updatedAcc[index] = {
                    ...updatedAcc[index],
                    'accomondation_address': places[0].formatted_address,
                    'location': {
                        type: 'Point',
                        coordinates: [places[0].geometry.location.lng(), places[0].geometry.location.lat()]
                    }
                };
                setAcc(updatedAcc);

            });
        });
    };



    const handleUploadComplete = (url) => {
        setData(prevDetails => ({
            ...prevDetails, video: url
        }));
    };

    const handleImageComplete = (newImages) => {
        setData(prevDetails => ({
            ...prevDetails, photos: newImages
        }));
    };
    useEffect(() => {
        initMap();
        initMap2();
    }, [acc,data.destination_address])

    useEffect(() => {
        const departureFiltered = handleSearch('departure');
        setDeparture(departureFiltered);
    }, [data.departure_station]);

    useEffect(() => {
        const arrivalFiltered = handleSearch('arrival');
        setArrival(arrivalFiltered);
    }, [data.arrival_station]);


    console.log(data)

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Create Itinerary
                    </div>
                </div>
                <div className="col-lg-6">
                </div>
            </div>
            <div className="outer_div mt-4">
                <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className="detail_wrapper">
                            <div className="small_heading mt-4">Destination Address</div>
                            <div className="name mt-2">
                                <input type='text' ref={inputDestination} placeholder='Type any destination' className='mt-2' name='destination_address' onChange={handleAddData} value={data.destination_address}/>
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <ul className="nav nav-items mt-3" id="myTabContent" role="tablist">
                            <li className="nav-item" onClick={() => handleTabs('DATES')} >
                                <div className={tabs === 'DATES' ? "nav-link active" : "nav-link"}
                                    id="tab1" data-toggle="tab" role="tab" aria-controls="tab1" aria-selected="true"
                                    style={{ backgroundColor: tabs !== 'DATES' ? 'transparent' : null, color: tabs !== 'DATES' ? '#56535b' : null }}>Exact dates</div>
                            </li>
                            <li className="nav-item" onClick={() => handleTabs('MONTH')} >
                                <div className={tabs === 'MONTH' ? "nav-link active" : "nav-link"}
                                    id="tab2" data-toggle="tab" role="tab" aria-controls="tab2" aria-selected="false"
                                    style={{ backgroundColor: tabs !== 'MONTH' ? 'transparent' : null, color: tabs !== 'MONTH' ? '#56535b' : null }}>Months</div>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className={tabs === 'DATES' ? "tab-pane active" : "tab-pane"} id="tab1" role="tabpanel" aria-labelledby="tab1">
                                <div className='p-3'>
                                    <div className='row'>
                                        <p>Select your dates.</p>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                            <div className="detail_wrapper">
                                                <div className="small_heading mt-2">Start Date</div>
                                                <div className="name mt-2">
                                                    <input type='datetime-local' className='mt-2' name='start_date' onChange={handleAddData} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                            <div className="detail_wrapper">
                                                <div className="small_heading mt-2">End Date</div>
                                                <div className="name mt-2">
                                                    <input type='datetime-local' className='mt-2' name='end_date' onChange={handleAddData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading mt-4">No of Days</div>
                                            <div className="name mt-2">
                                                <input type='text' placeholder='' className='mt-2' name='no_of_days' onChange={handleAddData} value={isNaN(data.no_of_days) ? 0 : data.no_of_days} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading mt-2">No of Nigths</div>
                                            <div className="name mt-2">
                                                <input type='text' placeholder='' className='mt-2' name='no_of_nights' onChange={handleAddData} value={isNaN(data.no_of_nights) ? 0 : data.no_of_nights} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={tabs === 'MONTH' ? "tab-pane active" : "tab-pane"} id="tab2" role="tabpanel" aria-labelledby="tab2">
                                <div className='p-3'>
                                    <div className='row'>
                                        <p>Select month to travel.</p>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                            <div className="detail_wrapper">
                                                <div className="small_heading mt-2">Choose here</div>
                                                <div className="name mt-2">
                                                    <input type='Month' className='mt-2' name='month' onChange={handleAddData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading mt-4">No of Days</div>
                                            <div className="name mt-2">
                                                <input type="text" maxLength={2} className='mt-2' name='no_of_days' onChange={handleAddData} value={isNaN(data.no_of_days) ? 0 : data.no_of_days} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading mt-2">No of Nigths</div>
                                            <div className="name mt-2">
                                                <input type="text" maxLength={2}  className='mt-2' name='no_of_nights' onChange={handleAddData} value={isNaN(data.no_of_nights) ? 0 : data.no_of_nights} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className="detail_wrapper">
                            <div className="small_heading mt-4">Charges</div>
                            <div className="name mt-2">
                                <input type="text" maxLength={4} placeholder='' className='mt-2' name='charges' onChange={handleAddData} value={isNaN(data.charges) ? 0 : data.charges} />
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className="detail_wrapper">
                            <div className="small_heading mt-4">No of People</div>
                            <div className="name mt-2">
                                <input type='text' placeholder='' className='mt-2' name='number_of_people' onChange={handleAddData} value={isNaN(data.number_of_people) ? 0 : data.number_of_people} />
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>

                        {data.photos.length > 0
                            ?
                            <div className='d-flex flex-wrap gap-3'>
                                {data.photos.map((element, index) => (
                                    <div className='imageSet' key={index + 1}>
                                        <img src={element} alt={element} className='itineraryImages' />
                                        <div className='icon' onClick={() => handleDeleteImages(element, index)} >
                                            <DeleteIcon />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            : null}

                        <div className='uploader' data-bs-toggle="modal" data-bs-target="#uploadImagesBackdrop">
                            <div className="detail_wrapper text-center">
                                <div className="small_heading">Upload Photos min(1) max(4)</div>
                                <div className="name mt-2"><ImagesIcon /></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2'>
                        {data.video !== "" ?
                            <div className='mt-4 text-center d-flex justify-content-center align-items-center gap-2 flex-column'>
                                <div className='imageSet' >
                                    <video width="100%" height='300' controls>
                                        <source src={data.video} type="video/mp4" />
                                    </video>
                                    <div className='icon' style={{ top: '7px', left: '488px' }} onClick={() => handleDeleteImages(data.video, null)} >
                                        <DeleteIcon />
                                    </div>
                                </div>

                                <button className='newBtn' data-bs-toggle="modal" data-bs-target="#uploadVideoBackdrop">Upload New</button>
                            </div>
                            :
                            <div className='uploader' data-bs-toggle="modal" data-bs-target="#uploadVideoBackdrop">
                                <div className="detail_wrapper text-center">
                                    <div className="small_heading">Upload Videos</div>
                                    <div className="name mt-2"><VideosIcon /></div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className="detail_wrapper">
                            <div className="small_heading mt-2">Description</div>
                            <div className="name mt-2">
                                <textarea type='text' rows={10} placeholder='Type here...' className='w-100 mt-1' name='description' onChange={handleAddData} />
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className="detail_wrapper">
                            <div className="small_heading mt-4">Departure Station</div>
                            <div className="name mt-2">
                                <input placeholder='Airport name or code' className='mt-2' name='departure_station' onChange={handleAddData} value={data.departure_station} />
                                <ul className="airports">
                                    {data.departure_station !== "" && departure.length > 0 ?
                                        <div className='airport-List' >
                                            {departure && departure.map(airport => (
                                                <li key={airport.iata} onClick={() => handleAddAirport(airport.name, airport.city, 'departure_station')}>
                                                    {airport.name} - {airport.city}
                                                </li>
                                            ))}
                                        </div>
                                        : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <div className="detail_wrapper">
                            <div className="small_heading mt-4">Arrival Station</div>
                            <div className="name mt-2">
                                <input placeholder='Airport name or code' className='mt-2' name='arrival_station' onChange={handleAddData} value={data.arrival_station} />
                                <ul className="airports">
                                    {data.arrival_station !== "" && arrival.length > 0 ?
                                        <div className='airport-List'>
                                            {arrival && arrival.map(airport => (
                                                <li key={airport.iata} onClick={() => handleAddAirport(airport.name, airport.city, 'arrival_station')}>
                                                    {airport.name} - {airport.city}
                                                </li>
                                            ))}
                                        </div>
                                        : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {data.no_of_days === 0 ? null :
                        <>
                            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                <div className="detail_wrapper">

                                    <div className="small_heading mt-4">Accommodations</div>
                                    <div className="name mt-2">

                                        {acc && acc.map((element, index) => (
                                            <div key={index + 1}>
                                                <label className='mt-4'>Destination Address</label>
                                                <input type="text" ref={(el) => inputRefs.current[index] = el} placeholder="Search accommodations" name="accomondation_address" id="accomondation_address" onChange={(e) => handleAddAccommodation(e, index)} />
                                                <div className='row mt-4'>
                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                        <label>Meals</label>
                                                        <input type='text' placeholder='Add Meal' onChange={handleAddData} name='meals' id={index} />
                                                        <div className='meals'>
                                                            {element.meals.map((element, index2) => (
                                                                <div className='chip' key={index2 + 1}>
                                                                    <div>{element}</div>
                                                                    <div style={{ cursor: "pointer" }} onClick={() => handleRemoveMeal(index2, index)}><CancelChip /></div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {index === currentMealIndex && data.meals !== "" ? (
                                                            <button onClick={() => handlePushMeals(index)} className="newBtn mt-3">Save Meal</button>
                                                        ) : null}

                                                    </div>
                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                        <label>Tours</label>
                                                        <input type='text' placeholder='Name of tour or provider' onChange={(e) => handleAddAccommodation(e, index)} name='tours' value={element.tours} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {data.no_of_days === acc.length ? null : <div onClick={handleMore} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center mt-4'>
                                        <More /> &nbsp; &nbsp;Click here to add accommodation.
                                    </div>}
                                </div>
                            </div>
                        </>}
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                        <button className='newBtn mt-4' onClick={createNew}
                            disabled={
                                data.destination_address === '' ||
                                // data.start_date === 0 ||
                                // data.end_date === 0 ||
                                data.no_of_nights === 0 ||
                                data.departure_station === '' ||
                                data.arrival_station === '' ||
                                data.no_of_days === 0 ||
                                data.video === '' ||
                                data.description === '' ||
                                data.photos.length === 0 ||
                                data.number_of_people === 0 ||
                                data.charges === 0 || acc.length === 0
                            }
                        >Submit</button>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="uploadVideoBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Upload video here.</h5>
                        </div>
                        <div className="modal-body">
                            <UploadVideo onUpload={handleUploadComplete} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Close</button>
                            <button type="button" className="newBtn" disabled={data.video === ''} data-bs-dismiss="modal">Done</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="uploadImagesBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Upload images here min(1) max(4).</h5>
                        </div>
                        <div className="modal-body">
                            <UploadImages onUpload={handleImageComplete} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Close</button>
                            <button type="button" className="newBtn" disabled={data.photos.length === 0} data-bs-dismiss="modal">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateItinerary
