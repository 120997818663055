import React, { useState } from 'react';
import AWS from 'aws-sdk';
import toast from 'react-hot-toast';

const UploadVideo = ({ onUpload }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [save, setSave] = useState(false);
    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile.type.indexOf('video/') !== 0) {
            toast.error('Please select a video file');
            return;
        }
        if (!selectedFile) {
            alert('Please select a file to upload');
            return;
        }

        setSave(true)
        // Configuring AWS SDK
        AWS.config.update({
            accessKeyId: 'AKIA4MTWOBTUL3ZVT64P',
            secretAccessKey: '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',
            region: 'us-east-1'
        });

        const s3 = new AWS.S3();
        const params = {
            Bucket: 'dannas3multimedia',
            Key: selectedFile.name,
            Body: selectedFile,
            ContentType: selectedFile.type
        };
        s3.upload(params).on('httpUploadProgress', (evt) => {
            const percentage = parseInt((evt.loaded * 100) / evt.total);
            setProgress(percentage);
        }).send((err, response) => {
            if (err) {
                console.error(err);
                return;
            } else {
                if (response?.Location !== '') {
                    toast.success('Uploads completed successfully');
                    onUpload(response.Location);
                    setTimeout(() => {
                        setProgress(0)
                        setSave(false)
                        setSelectedFile(null)
                    }, 2000)
                } else {
                    toast.error("Video could not upload")
                }
            }
        })

    };



    return (
        <div className='text-center w-100'>
            {!save ? <input type="file" onChange={handleFileInputChange} accept="video/*" /> : null}
            {selectedFile && save ?
                <>
                    {progress > 0 ?
                        <div className='w-100 mt-4 text-center d-flex justify-content-center align-items-center gap-2 flex-column'>
                            <p>Please wait and do not close this pop-up as your video is currently being uploaded its take time.</p>
                            <div className="progress w-100">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progress}%` }}
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {progress}%
                                </div>
                            </div>
                        </div>

                        :
                        <div className=' mt-4 text-center d-flex justify-content-center align-items-center gap-2 flex-column'>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p>Please wait and do not close this pop-up as your video is currently being uploaded its take time.</p>
                        </div>
                    }
                </>
                : null}
            {!save ? <button onClick={handleUpload} className='mt-3 newBtn' disabled={!selectedFile}>Upload Video</button> : null}
        </div>
    );
};

export default UploadVideo;
