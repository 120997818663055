import React, { useEffect, useState } from 'react'
import Loader from '../Loader'
import toast from 'react-hot-toast'
import { getRequest } from '../../apis/functions'
import APIS from '../../apis/routes';
import { Link, useParams } from 'react-router-dom';
import { AccessTime } from '../../common/Icons';
import moment from 'moment';

const PaymentDetails = () => {
    const [load, setLoad] = useState(true)
    const [details, setDetails] = useState({})
    const router = useParams()

    const fetchDetails = async () => {
        try {
            let result = await getRequest(`${APIS.GET_PAYMENT_DETAILS}${router.id}`)
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                setDetails(result.data.data)
                setLoad(false)
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
        }
    }
    useEffect(() => {
        fetchDetails()
    }, [])
    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="pagetitle mb-5 mt-3" style={{ width: "fit-content" }}>
                        Payment Details
                    </div>
                    <div className="outer_div mt-4">
                        <div className="row">
                            <div className="col-lg-4 border_right">
                                <div className="detail_wrapper mt-3">
                                    <div className="small_heading">Transaction ID</div>
                                    <div className="name mt-2">{details.txn_id}</div>
                                </div>
                                <div className="detail_wrapper mt-3">
                                    <div className="small_heading">Amount</div>
                                    <div className="name mt-2">${details.amount}</div>
                                </div>
                                <div className="detail_wrapper mt-3">
                                    <div className="small_heading">Status</div>
                                    <div className="name mt-2">
                                        {details.status === 0 ?
                                            <span className="badge pending">PENDING <AccessTime /></span>
                                            : details.status === 1 ?
                                                <span className="badge accepted">COMPLETED <AccessTime /></span>
                                                : details.status === 2 ?
                                                    <span className="badge cancelled">REJECTED <AccessTime /></span>
                                                    : null
                                        }
                                    </div>
                                </div>
                                <div className="detail_wrapper mt-3">
                                    <div className="small_heading">Date</div>
                                    <div className="name mt-2">{moment(details.createdAt).format('LLL')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="detail_wrapper mt-3">
                                    <div className="small_heading">User ID</div>
                                    <div className="name mt-2">{details.userId}
                                    </div>
                                </div>
                                <div className="detail_wrapper mt-3">
                                    <div className="small_heading">Full Name</div>
                                    <div className="name mt-2">{details.paid_by}
                                    </div>
                                </div>
                                <div className="detail_wrapper mt-3">
                                    <div className="small_heading">Email</div>
                                    <div className="name mt-2">{details.paid_by_email}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }
        </>
    )
}

export default PaymentDetails


