import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const Authentic = () => {
    var auth = localStorage.getItem("x_token")
    return (
        <>
            {
                !auth
                    ?
                    <Outlet />
                    :
                    <Navigate to='/'></Navigate>
            }
        </>

    )
}

export default Authentic