import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes';
import { toaster } from '../../apis/commonFunctions';
import Image from '../../common/Image';
import Loader from '../Loader';
import { EditData } from '../../common/Icons';
import Input from '../../common/Input';
import toast from 'react-hot-toast';

const UserDetails = () => {

    const [details, setDetails] = useState({ firstname: "", lastname: "" });
    const [edit, setEdit] = useState(false)
    const [load, setLoad] = useState(true)
    const { id } = useParams();

    async function fetchDetails() {
        try {
            const { data } = await getRequest(APIS.USER + "/" + id);
            if (data.status) {
                setDetails(data.data)
                setLoad(false)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    function handleChanges(e) {
        setDetails({
            ...details,
            [e.target.name]: e.target.value.trim()
        })
    }

    async function updateProfile() {
        try {
            const result = await patchRequest(APIS.EDIT_USER + id, details);
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                fetchDetails()
                setEdit(false)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }
    useEffect(() => {
        fetchDetails()
    }, [id]);

    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="pagetitle mb-5 mt-3 w-100" style={{ width: "fit-content" }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <div>
                                User Details
                            </div>
                        </div>
                    </div>
                    <div className="outer_div mt-2">
                        <div className='row'>
                            {!edit ? <div className='d-flex justify-content-end align-items-end'>
                                {/* <div className='editIcon' onClick={() => setEdit(true)}>
                                    <EditData />
                                </div> */}
                                <div style={{ height: '49px' }}></div>

                            </div> :
                                <div style={{ height: '49px' }}></div>
                            }
                        </div>
                        <div className={"row"}>
                            <div className="col-lg-2 col-md-2">
                                <div className="profile_image">
                                    <Image src={details.profile_pic!==""?details.profile_pic:""} alt="" className="rounded-circle img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-10">
                                <ul className="profile_list p-0 row custom-css">

                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Full Name</div>
                                            <div className="name mt-2">
                                                {!edit ?
                                                    <>
                                                        {details.firstname===""?'Not added yet.':details.firstname + " " + details.lastname}
                                                    </>
                                                    :
                                                    <div className="d-flex justify-content-start gap-3 flex-column">
                                                        <Input type={"text"} placeholder='firstname' value={details.firstname} disabled={edit ? false : true} onChange={handleChanges} name="firstname" />
                                                        <Input type={"text"} placeholder="lastname" value={details.lastname} disabled={edit ? false : true} onChange={handleChanges} name="lastname" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </li></div>
                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Phone</div>
                                            <div className="name mt-2">{details.mobile === "" ? 'Not added yet.' : details.country_code + " " + details.mobile}</div>
                                        </div>
                                    </li></div>

                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Email</div>
                                            <div className="name mt-2">{details.email !== "" ? <div className='text-lowercase'>{details.email}</div> : "Not added yet."}</div>
                                        </div>
                                    </li></div>
                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Address</div>
                                            <div className="name mt-2">{details.address === "" ? 'Not added yet.' : details.address}</div>
                                        </div>
                                    </li></div>
                                    <div className="col-md-6"><li >
                                        <div className="detail_wrapper">
                                            {edit ? <button className='newBtn' onClick={updateProfile} disabled={details.firstname === "" || details.lastname === ""}>Save Changes</button> : null}
                                        </div>
                                    </li></div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default UserDetails