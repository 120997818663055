import React, { useEffect, useState } from 'react'
import Loader from '../Loader'
import toast from 'react-hot-toast'
import { getRequest } from '../../apis/functions'
import APIS from '../../apis/routes';
import { Link, useParams } from 'react-router-dom';

const BookingDetails = () => {
    const [load, setLoad] = useState(true)
    const [details, setDetails] = useState({})
    const router = useParams()

    const fetchDetails = async () => {
        try {
            let result = await getRequest(`${APIS.GET_BOOKING_DETAILS}${router.id}`)
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                setDetails(result.data.data)
                setLoad(false)
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
        }
    }
    useEffect(() => {
        fetchDetails()
    }, [])
    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="pagetitle mb-5 mt-3" style={{ width: "fit-content" }}>
                        Booking Details
                    </div>
                    <div className="outer_div mt-4">
                        <div className="row">
                        <div className="col-lg-4 border_right">
                                <div className="sub_title  mb-4">Booked Itinerary Details</div>
                                <div className="p-0 single_column mt-2">
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">HOST</div>
                                            <div className="name mt-2">{details.host_name}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Price</div>
                                            <div className="name mt-2">${details.charges}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">No of Days.</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.no_of_days}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">No of Night.</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.no_of_nights}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">No of Persons allowed</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.no_of_persons}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Departure Station</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.departure_station}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Arrival Station</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.arrival_station}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Destination Address</div>
                                            <div className="name mt-2">{details.destination_address}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Description</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.description === "" ? 'Not Added yet.' : details.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 border_right">
                                <div className="sub_title mb-4">Booked by</div>
                                <div className="p-0 single_column mt-2">
                                    <div>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Booking ID</div>
                                            <Link className='view' to={'/bookings/' + details._id} data-bs-toggle="tooltip" data-bs-placement="top" title="View Details">
                                            <div className="name mt-2">{details._id}</div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Full Name</div>
                                            <div className="name mt-2">{details.booked_by}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Contact Info</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.booked_by_contact}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">No of Persons Booked</div>
                                            <div className="name mt-2" style={{ textTransform: 'lowercase' }}>{details.no_of_persons}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading mb-2">Booking Status</div>
                                            {details.status === 0 ?
                                                <span className="badge pending">PENDING</span>
                                                : details.status === 1 ?
                                                    <span className="badge accepted">CONFIRMED</span>
                                                    : details.status === 2 ?
                                                        <span className="badge cancelled">CANCELLED</span>
                                                        : <span className="badge cancelled">CANCELLED</span>
                                            }                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                                <div className="sub_title  mb-4">Payment Details</div>
                                <div className="p-0 single_column mt-2">
                                    <div>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Transaction ID</div>
                                            <Link className='view' to={'/payments/' + details.paymentId} data-bs-toggle="tooltip" data-bs-placement="top" title="View Details">
                                            <div className="name mt-2">{details.paymentId}</div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Amount</div>
                                            <div className="name mt-2">${details.paid_amount}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">Paid by</div>
                                            <div className="name mt-2">{details.paid_amount_by}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading mb-2">Status</div>
                                            {details.payment_status === 0 ?
                                                <span className="badge pending">PENDING</span>
                                                : details.status === 1 ?
                                                    <span className="badge accepted">COMPLETED</span>
                                                    : details.status === 2 ?
                                                        <span className="badge rejected">FAILED</span>
                                                        : <span className="badge rejected">FAILED</span>
                                            }                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>

                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default BookingDetails
