import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Sidebar from '../common/Sidebar'
import Header from '../common/Header'
import Login_Signup from '../components/admin/Login_Signup'

const Protected = () => {
  var auth = localStorage.getItem("x_token")
  return (
    <>
      {
        auth
          ?
          <Outlet />
          :
          <Navigate to="/" />
      }
    </>

  )
}

export default Protected