import { configureStore } from "@reduxjs/toolkit";
import detailsSlice from "./slices/detailsSlice";
import privillageSlice from "./slices/privillageSlice";
import countSlice from "./slices/countSlice";

export const store = configureStore({
    reducer : {
        details : detailsSlice,
        privillages : privillageSlice,
        count:countSlice
    }
})