import React, { useState } from 'react'
import Input from '../../common/Input'
import Button from '../../common/Button'
import { postRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import { toaster } from '../../apis/commonFunctions'
import toast from 'react-hot-toast'

export default function ChangePassword() {
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('')
    const [password, setPassword] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    })

    function handleChanges(e) {
        setMessage('')
        if (password.new_password === password.confirm_password) {
            setMessage('')
        }
        setPassword({
            ...password,
            [e.target.name]: e.target.value.trim()
        })
    }

    async function submitForm(event) {
        try {
            if (password.new_password.length < 6) {
                setMessage('New password must be 6 character or long!.')
            } else if (password.new_password !== password.confirm_password) {
                setMessage('New Password not matched!.')
            } else {
                const result = await postRequest(APIS.CHANGE_PASSWORD, password)
                if (!result.data.status) {
                    toast.error(result.data.message)
                    setMessage(result.data.message)
                } else {
                    toast.success(result.data.message)
                    setMessage('')
                    setPassword({
                        new_password: "",
                        old_password: "",
                        confirm_password: ""
                    })
                }
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="login_area">
                            <div className="mt-5">
                                <div className="group">
                                    <Input className={'mt-2'} type={'password'} onChange={handleChanges} autoComplete={'off'} placeholder={'********'} value={password.old_password} name="old_password" label={'Old Password'} />
                                </div>
                                <div className="group mt-4">
                                    <Input className={'mt-2'} value={password.new_password} type={'password'} name="new_password" placeholder='********' onChange={handleChanges} label={'New Password'} />
                                </div>
                                <div className="group mt-4">
                                    <Input className={'mt-2'} value={password.confirm_password} type={'password'} name="confirm_password" placeholder='********' onChange={handleChanges} label={'Confirm Password'} />
                                </div>
                                <div className='errorMessage mt-3'>
                                    {message != "" ? message : null}
                                </div>
                                <div className="mt-3">
                                    <button className='newBtn' onClick={submitForm} disabled={password.old_password === '' || password.new_password === '' || password.confirm_password === ''}>Save Changes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
