import React, { useEffect, useState } from 'react'
import { getRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Card from '../../common/Card'
import Loader from '../Loader'

export default function Dashboard() {
    const [data, setData] = useState({});
    const [load, setLoad] = useState(true);
    const nav = useNavigate();

    const fetchData = async () => {
        try {
            const result = await getRequest(APIS.DASHBOARD);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    toast.info(result.data.message)
                }
            } else {
                setData(result.data.data)
                setLoad(false)
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="pagetitle">
                        Dashboard
                    </div>
                    <div className="row mt-5">
                        <Card Provider={"Users"} number={data?.users ?? 0} />
                        <Card Provider={"Itineraries"} number={data?.itineraries ?? 0} />
                    </div>
                </>
            }
        </>
    )
}
