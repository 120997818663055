import toast from 'react-hot-toast'

const toaster = (message="", status=true) => {
    if (status) {
        toast.success(message);
    } else {
        toast.error(message)
    }
}

export { toaster }