import React, { useEffect, useState } from 'react'
import { AccessTime, Block, Calculator, Delete, Eye, PaymentMethod, Pencil, Search } from '../../common/Icons'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import Button from '../../common/Button'
import Switch from "react-switch";
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import Pagination from '../../common/Pagination'
import Search_input from '../../common/Search_input'
import moment from 'moment/moment'
import Loader from '../Loader'

const Payments = () => {
    const [all, setAll] = useState([]);
    const router = useNavigate()
    const [skip, setSkip] = useState(0)
    const [search, setSearch] = useState("");
    const [limit, setLimit] = useState(8);
    const [load, setLoad] = useState(true);
    const [mainid, setMainId] = useState("");
    const [total, setTotal] = useState(0)
    const [params] = useSearchParams();
    const [obj, setObj] = useState({})
    // console.log(Array.from(params.entries())," thishsishihsih")


    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.GET_PAYMENTS}?search=${search}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)
                setLoad(false)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    function handlePageClick({ selected }) {
        setSkip(selected * limit)
    }

    const deleteFinance = async (id) => {
        try {
            const { data } = await deleteRequest(`${APIS.USER}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const handleBlock = async () => {
        try {
            const { data } = await patchRequest(`${APIS.BLOCK_UNBLOCK_USER}/${obj._id}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [search, skip])

    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="top_bar row align-items-center mt-3">
                        <div className="col-lg-6">
                            <div className="pagetitle">
                                Payments <br />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Search_input placeholder={'Search by booking Id or transaction Id...'} search={search} value={search} handleSearch={(e) => setSearch(e.target.value)} icon={<Search />} className='search_input justify-content-start w-100' />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colSpan="4">
                                        <th>Booking ID</th>
                                        <th>Transaction ID</th>
                                        <th>Amount</th>
                                        <th>User</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {all.length > 0
                                        ?
                                        all.map((element, index) => (
                                            <tr key={element._id}>
                                                <td>{element.booking_Id}</td>
                                                <td>{element.txn_id}</td>
                                                <td>
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <div>${element.amount}</div>
                                                        <div>CAD</div>

                                                    </div>
                                                </td>
                                                <td>
                                                    {element.host_name}
                                                </td>
                                                <td>
                                                    {element.status === 0 ?
                                                        <span className="badge pending">PENDING <AccessTime /></span>
                                                        : element.status === 1 ?
                                                            <span className="badge accepted">COMPLETED <AccessTime /></span>
                                                            : element.status === 2 ?
                                                                <span className="badge cancelled">REJECTED <AccessTime /></span>
                                                                : null
                                                    }
                                                </td>
                                                <td>
                                                    {moment(element.createdAt).format('lll')}
                                                </td>
                                                <td>
                                                    <div className="actions">
                                                        <Link className='view' to={'/payments/' + element.payment_Id} data-bs-toggle="tooltip" data-bs-placement="top" title="View Details">
                                                            <button className='block'>
                                                                <Eye />
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : <NoDataRow />}
                                        {all.length<0?null:
                                        <>
                                    {total > limit && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={all.length} limit={limit} /></td></tr>}
                                        </>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ConfirmationPopup message={"Do you really want to delete this user ?"} onYes={() => deleteFinance()} />
                </>
            }

            <div className="modal fade" id="blockBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='p-3'>
                            <h5 className="modal-title" id="staticBackdropLabel">
                                {obj.admin_blocked ?
                                    ' Are you sure want to Unblock this user?'
                                    :
                                    'Are you sure want to block this user?'
                                }
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleBlock}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payments
