import React, { useState } from 'react';
import AWS from 'aws-sdk';
import toast from 'react-hot-toast';

const UploadImages = ({ onUpload }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [save, setSave] = useState(false);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
            setSelectedFiles(Array.from(event.target.files));
        } else if (selectedFiles.length > 4) {
            toast.error('Images should be maximum 4!')
        } else {
            toast.error('Please select valid image!')
        }
    };

    const handleUpload = () => {
        if (selectedFiles.length === 0) {
            toast.error('Please select one or more files to upload');
            return;
        }
        if (selectedFiles.length > 4) {
            toast.error('Images limit should be maximum 4!')
            setSelectedFiles([])
            return;
        }

        setSave(true)
        // Configuring AWS SDK
        AWS.config.update({
            accessKeyId: 'AKIA4MTWOBTUL3ZVT64P',
            secretAccessKey: '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',
            region: 'us-east-1'
        });

        const s3 = new AWS.S3();
        const uploadPromises = selectedFiles.map(file => {
            return new Promise((resolve, reject) => {
                const params = {
                    Bucket: 'dannas3multimedia',
                    Key: file.name,
                    Body: file,
                    ContentType: file.type
                };

                s3.upload(params).on('httpUploadProgress', (evt) => {
                    const percentage = parseInt((evt.loaded * 100) / evt.total);
                    setProgress(percentage);
                }).send((err, data) => {
                    if (err) {
                        console.error('Error uploading file:', file.name, err);
                        reject(err);
                    } else {
                        resolve(data.Location);
                    }
                });
            });
        });


        // Execute all upload promises concurrently
        Promise.all(uploadPromises).then((uploadedLocations) => {
            toast.success('Uploads completed successfully');
            setProgress(0);
            setSave(false);
            setSelectedFiles([]);
            onUpload(uploadedLocations); // Send array of uploaded file locations to callback
        }).catch((error) => {
            toast.error(error.message);
            setProgress(0);
            setSave(false);
        });

    };



    return (
        <div className='text-center w-100'>
            {!save ? <input type="file" onChange={handleFileInputChange} multiple accept="image/*" /> : null}
            {selectedFiles.length > 0 && save ?
                <>
                    {progress > 0 ?
                        <div className='w-100 mt-4 text-center d-flex justify-content-center align-items-center gap-2 flex-column'>
                            <p>Please wait and do not close this pop-up as your video is currently being uploaded its take time.</p>
                            <div className="progress w-100">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progress}%` }}
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {progress}%
                                </div>
                            </div>
                        </div>

                        :
                        <div className=' mt-4 text-center d-flex justify-content-center align-items-center gap-2 flex-column'>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p>Please wait and do not close this pop-up as your video is currently being uploaded its take time.</p>
                        </div>
                    }
                </>
                : null}
            {!save ? <button onClick={handleUpload} className='mt-3 newBtn' disabled={selectedFiles.length === 0}>Upload Image</button> : null}
        </div>
    );
};

export default UploadImages;
