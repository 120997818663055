import React from 'react'
import { Home, Payment, User, Luggage, Booking, PrivacyIcon, TermsIcon, Rating, GroupIcon, CancelPolicy } from './Icons'
import { NavLink } from 'react-router-dom';

export default function Sidebar() {

    return (
        <>
            <aside id='sidebar' className='sidebar'>
                <ul className='sidenav' id='sidenav'>

                    {/* Dashboard */}
                    <div className="menu_title">Dashboard</div>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/" className="nav-link">
                            <span><Home /></span>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>

                    <div className="menu_title">Management</div>

                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/user-management" className="nav-link">
                            <span><User /></span>
                            <span>Users</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/itinerary-management" className="nav-link">
                            <span><Luggage /></span>
                            <span>Itineraries</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/bookings" className="nav-link">
                            <span><Booking /></span>
                            <span>Bookings</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/ratings-reviews" className="nav-link">
                            <span><Rating /></span>
                            <span>Ratings & Reviews</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/payments" className="nav-link">
                            <span><Payment /></span>
                            <span>Payments</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/groups" className="nav-link">
                            <span><GroupIcon /></span>
                            <span>Groups</span>
                        </NavLink>
                    </li>
                    <div className="menu_title">Content Management</div>

                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/privacy-policy" className="nav-link">
                            <span><PrivacyIcon /></span>
                            <span>Privacy Policy</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/cancellation-policy" className="nav-link">
                            <span><CancelPolicy /></span>
                            <span>Cancellation Policy</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/terms-and-condition" className="nav-link">
                            <span><TermsIcon /></span>
                            <span>Terms & conditions</span>
                        </NavLink>
                    </li>
                </ul>
            </aside>
        </>
    )
}


